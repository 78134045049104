<template>
  <div style="width:1000px">
    <div style="">
      <a-form ref='form' @finish="onFinish" :model='mmInfo'>
        <a-form-item label="供应商名称" name="mmSup"
            :labelCol='labelCol' :wrapperCol='wrapperCol'
            :rules="rules.mmSup">
          <a-input v-model:value="mmInfo.mmSup"/>
        </a-form-item>
        <a-form-item label="物料件号" name="mmCode"
            :labelCol='labelCol' :wrapperCol='wrapperCol'
            :rules="rules.mmCode">
          <a-input v-model:value="mmInfo.mmCode"/>
        </a-form-item>
        <a-form-item label="物料名称" name="mmName"
            :labelCol='labelCol' :wrapperCol='wrapperCol'
            :rules="rules.mmName">
          <a-input v-model:value="mmInfo.mmName"/>
        </a-form-item>
        <a-form-item label="生产批次" name="mmBatch"
            :labelCol='labelCol' :wrapperCol='wrapperCol'
            :rules="rules.mmBatch">
          <a-input v-model:value="mmInfo.mmBatch" aria-placeholder="请输入'年月日'+'-'+'四位数字'如：20230101-0001"/>
        </a-form-item>
        <a-form-item label="收货日期" name="mmDate"
            :labelCol='labelCol' :wrapperCol='wrapperCol'
            :rules="rules.mmDate">
          <a-input v-model:value="mmInfo.mmDate"/>
        </a-form-item>
        <a-form-item label="MPN" name="mmMpn"
            :labelCol='labelCol' :wrapperCol='wrapperCol'
            :rules="rules.mmMpn">
          <a-input v-model:value="mmInfo.mmMpn"/>
        </a-form-item>
        <a-form-item label="规格型号" name="mmXh"
            :labelCol='labelCol' :wrapperCol='wrapperCol'
            :rules="rules.mmXh">
          <a-input v-model:value="mmInfo.mmXh"/>
        </a-form-item>
        <a-form-item label="每箱数量" name="mmNum"
            :labelCol='labelCol' :wrapperCol='wrapperCol'
            :rules="rules.mmNum">
          <a-input v-model:value="mmInfo.mmNum"/>
        </a-form-item>
        <a-form-item label="导出张数" name="num"
            :labelCol='labelCol' :wrapperCol='wrapperCol'
            :rules="rules.num">
          <a-input-number :max="100" :min="0" style="width: 100%" v-model:value="mmInfo.num" placeholder="请输入导出份数"/>
        </a-form-item>
        <a-form-item :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-button :loading="showLoad" type="primary" html-type="submit">导出</a-button>
        </a-form-item>
      </a-form>
    </div>
    <div style="background-color: azure;width: 100%;margin-top: 20px">
      PDF预览
    </div>
    <div style="display: flex;justify-content: center;margin-top: 20px">
      <div ref="refPdf">
        <div class="pdfStyle" v-for="(item,index) in mmInfo.num" :key="index">
          <table border=1 cellspacing="0" width="100%" bordercolor="#000000">
            <tr>
              <th class="titleTable">供应商名</th>
              <th class="contentTable" colspan="3">{{ mmInfo.mmSup }}</th>
            </tr>
            <tr>
              <th class="titleTable">内部件号</th>
              <th class="contentTable">{{ mmInfo.mmCode }}</th>
              <th class="titleTable">规格型号</th>
              <th class="contentTable">{{ mmInfo.mmXh }}</th>
            </tr>
            <tr>
              <th class="titleTable">物料名称</th>
              <th class="contentTable">{{ mmInfo.mmName }}</th>
              <th class="titleTable">每箱数量</th>
              <th class="contentTable">{{ mmInfo.mmNum }}</th>
            </tr>
            <tr>
              <th class="titleTable">生产批次</th>
              <th class="contentTable">{{ mmInfo.mmBatch }}</th>
              <th class="contentTable" colspan="2" rowspan="3">
                &nbsp;&nbsp;
                <qrcode-vue :value="qrCodes[index]" :size=110></qrcode-vue>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <span>{{index+1}}/{{mmInfo.num}}</span>
              </th>
            </tr>
            <tr>
              <th class="titleTable">收货日期</th>
              <th class="contentTable">{{ mmInfo.mmDate }}</th>
            </tr>
            <tr>
              <th class="titleTable">MPN</th>
              <th class="contentTable">{{ mmInfo.mmMpn }}</th>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, onMounted, ref, watch} from 'vue'
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import QrcodeVue from 'qrcode.vue'
import {v1 as uuid} from 'uuid';

export default defineComponent({
  components: {
    QrcodeVue
  },

  setup() {
    let qrCodes = ref([]);
    let showLoad = ref(false);
    const refPdf = ref(null);
    onMounted(() => {
      refPdf
    })
    let mmInfo = reactive({
      mmSup: '',
      mmCode: '',
      mmName: '',
      mmBatch: '',
      mmDate: '',
      mmMpn: '',
      mmXh: '',
      mmNum: '',
      num: null,
    });
    const rules = reactive({
          mmCode: [
            {required: true, message: '请输入物料件号', trigger: 'blur'},
          ],
          mmBatch: [
            {required: true, message: '请输入生产批次', trigger: 'blur'},
          ],
          mmNum: [
            {required: true, message: '请输入每箱数量', trigger: 'blur'},
          ],
          num: [
            {required: true, message: '请输入导出张数', trigger: 'blur'},
          ],
        }
    )
    const labelCol = reactive({
      xs: {span: 24},
      sm: {span: 8}
    })
    const wrapperCol = reactive({
      xs: {span: 24},
      sm: {span: 16}
    })
    const handleExport = () => {
      downloadPDF(refPdf.value, mmInfo.num)
    }
    const onFinish = () => {
        showLoad.value = true
        handleExport();
    }
    const canvas2PDF = (canvas, num) => {
      let perHeight = 67.5;
      let imgWidth = 190;
      let imgHeight = perHeight * num;
      // 第一个参数：l：横向  p：纵向
      // 第二个参数：测量单位（"pt"，"mm", "cm", "m", "in" or "px"）
      let pdf = new jsPDF("l", "mm", [imgWidth, perHeight]);
      let position = 0;
        for (let i = 1; i <= num; i++) {
          var pageNum = 'page';
          if (num >= 100) {
            if (i < 10) {
              pageNum += '00';
            } else if (i < 100) {
              pageNum += '0';
            }
          } else if (num >= 10) {
            if (i < 10) {
              pageNum += '0';
            }
          }
          console.log(pageNum + i);
          pdf.addImage(canvas.toDataURL("image/jpeg", 1.0), 'JPEG', 0, position, imgWidth, imgHeight)
          position = position - perHeight;
          if (i !== num) {
            pdf.addPage();
          }
        }
      pdf.save("客户标签.pdf", {returnPromise: true}).then(()=>{
        showLoad.value = false;
      })
    }
    const downloadPDF = (page, num) => {
      html2canvas(page).then(function (canvas) {
        canvas2PDF(canvas, num);
      });
    };
    watch(mmInfo, (nV) => {
      qrCodes.value = [];
      if (!nV.num || nV.num == 0) {
        return;
      }
      console.log('start');
      for (let i = 0; i < nV.num; i++) {
        let uuidNo = uuid().replace(new RegExp("-","gm"), "");
        let qrCode = `${mmInfo.mmCode}@${mmInfo.mmNum}@${mmInfo.mmBatch}@${uuidNo}@[@]10`;
        qrCodes.value.push(qrCode);
        console.log(qrCode);
      }
      console.log('end');
    })
    return {
      showLoad, qrCodes, refPdf, mmInfo, rules, labelCol, wrapperCol, handleExport, onFinish,
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pdfStyle {
  width: 19cm;
  height: 6.75cm;
  border: 1px #000000 solid;
  font-size: 25px;
}

.pdfStyle tr th {
  text-align: left;
}

.titleTable {
  width: 20%;
  white-space: nowrap;
}

.contentTable {
  width: 30%;
  white-space: nowrap;
  overflow: hidden;
  /*text-overflow: ellipsis;*/
}
</style>
